import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Seo from "../components/seo"

const BreadCrumbs = styled.span`
  font-size: 12px;
  color: #3e4c59;
  font-weight: 500;
  & a {
    color: #3e4c59;
    text-decoration: none;
    cursor: pointer;
  }
  @media screen and (min-width: 1050px) {
    font-size: 14px;
  }
`
const Container = styled.div`
  margin: 16px 20px 96px;
  @media screen and (min-width: 1050px) {
    margin: 24px 0 96px;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  margin-top: 48px;
  margin-bottom: 32px;
  color: #1f2933;
  @media screen and (min-width: 1050px) {
    margin: 96px 0 64px;
  }
`

const U = styled.span`
  border-bottom: 1px solid #dfecf4;
`

const SingleDoc = ({ data, location }) => {
  const pdf = data.markdownRemark.frontmatter.circolare
  const title = data.markdownRemark.frontmatter.title

  return (
    <>
      <Seo
        title={title}
        description="Le circolari fornite dallo Studio commercialisti associati Benetti e Filippini"
      />
      <Container>
        <BreadCrumbs>
          <U>
            <Link to="/">Home </Link>
          </U>
          &gt;{" "}
          <U>
            <Link to="/circolari">Circolari </Link>
          </U>
          &gt;{" "}
          <U>
            <Link to={"/" + title}>{title} </Link>
          </U>
        </BreadCrumbs>
        <article>
          <Title>{title}</Title>
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            Premi qui per scaricare la circolare {title}.
          </a>
        </article>
      </Container>
    </>
  )
}

export default SingleDoc

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        circolare
      }
    }
  }
`
